var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": ""
    }
  }, [_vm.myGudang ? _c('header', [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.nama_gudang))])])]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.so.tanggal))]), _vm.so.gm ? _c('section', {
    staticClass: "mt-1"
  }, [_c('p', [_c('strong', [_vm._v("General Manager")])]), _c('p', [_vm._v(" " + _vm._s(_vm.so.gm.nama_lengkap) + " ")])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e()], 1), _vm.isGM && !_vm.so.id_gm ? _c('section', {
    staticClass: "d-flex justify-content-end my-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.approve($event);
      }
    }
  }, [_vm._v("Approve Stok Opname")])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c('b-form-checkbox', {
          on: {
            "change": function change($event) {
              return _vm.updatePenyesuaian(index);
            }
          },
          model: {
            value: item.checked,
            callback: function callback($$v) {
              _vm.$set(item, "checked", $$v);
            },
            expression: "item.checked"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(so)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.stok_opname_id) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.jumlah ? item.jumlah - item.stok_real : 0) + " ")];
      }
    }, {
      key: "cell(stok_real)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.stok_real,
            callback: function callback($$v) {
              _vm.$set(item, "stok_real", $$v);
            },
            expression: "item.stok_real"
          }
        })];
      }
    }, {
      key: "cell(penyesuaian)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.checked ? _c('b-form-input', {
          model: {
            value: item.penyesuaian,
            callback: function callback($$v) {
              _vm.$set(item, "penyesuaian", $$v);
            },
            expression: "item.penyesuaian"
          }
        }) : _c('span', [_vm._v(_vm._s(item.penyesuaian))])];
      }
    }])
  })], 1), _vm.alreadyApproved ? _c('section', {
    staticClass: "my-2 d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan Penyesuaian")])], 1) : _vm._e()], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.save($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }